// Libraries
import React from 'react';
import {Row, Form, Modal} from 'antd';
import {useAppDispatch} from '../../store/store';
import {useSelector} from "react-redux";

// Store
import {reassignCarrier} from "../../store/redux/actions/transportActions";
import * as transportSelectors from "../../store/redux/selectors/transportSelectors";

// Components
import ButtonComponent from '../subComponents/ButtonComponent';
import FormItemComponent from '../subComponents/FormItemComponent';
import SelectComponent from "../subComponents/SelectComponent";


type PropsType = {
  transportId: string | null;
  onToggleModal: (visible: boolean, transportId: string | null) => void;
};
const ReassignCarrierFormModal = (props: PropsType) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { transportId, onToggleModal } = props;
  const { carriers } = useSelector(transportSelectors.transportParamsSelector);

  const handleSubmit = (data: { carrierId: string }) => {
    dispatch(reassignCarrier({ transportEngineId: transportId!, carrierId: data.carrierId }));
    onToggleModal(false, null);
  }

  return (
    <Modal title={`Reasignar transportista`}
           width={500}
           open={true} maskClosable closable={false} keyboard={false} destroyOnClose={true}
           footer={[
             <ButtonComponent key={'cancel'} text={'Cerrar'}
                              className={'w-32 h-8 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                                'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'}
                              onClick={() => onToggleModal(false, null)} />,

             <ButtonComponent key={'submit'} text={`Enviar`}
                              className={'w-32 h-8 text-white bg-blue-dkt-400 hover:bg-blue-dkt-500 focus:outline-none focus:ring-2 ' +
                                'focus:ring-offset-2 focus:ring-blue-dkt-300 after:content-none'}
                              onClick = {() => form.submit()}/>,
           ]}>
      <Form layout={"vertical"}
            form={form}
            onFinish={handleSubmit}>
        <Row>
          <FormItemComponent name={'carrierId'}
                             label={'Nuevo transportista'}
                             required={true}
                             className={'w-full'}
                             child={<SelectComponent list={carriers} />}
          />
        </Row>
      </Form>
    </Modal>
  );
}

export default ReassignCarrierFormModal;
