// Libraries
import React from 'react';
import {Col, message, Row, Tooltip, Upload} from 'antd';
import {DeleteOutlined, FileAddOutlined} from "@ant-design/icons";
import {RcFile} from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";

// Components
import ButtonComponent from "../../subComponents/ButtonComponent";
import {ERROR_MODAL} from "../../subComponents/responseModals";
import InputComponent from "../../subComponents/InputComponent";


type PropsType = {
  fileList: RcFile[];
  setFileList:  (fileList: RcFile[]) => void;
}
const Files = (props: PropsType) => {
  const { fileList, setFileList } = props;
  const acceptedFormats = "application/pdf,image/jpeg,image/png";

  const beforeUpload = (file: RcFile, size: number) => {
    const isLessToSize = file.size / 1024 / 1024 < size;
    if (!isLessToSize) {
      message.error(`El archivo debe ser menor o igual a ${size}MB`);
    }
    return isLessToSize;
  }

  const dummyRequest = (options: UploadRequestOption) => {
    const { file, onSuccess, onError } = options;

    if (!acceptedFormats.includes((file as RcFile).type)) {
      ERROR_MODAL("El archivo no es el correcto. Por favor asegúrese que es un PDF.");
      setTimeout(() => {
        onError?.(new Error("Formato inválido"));
      }, 0);
      return;
    }

    setTimeout(() => {
      setFileList([...fileList, file as RcFile])
      onSuccess?.("ok");
    }, 0);
  };

  const handleRemoveFile = (fileIndex: number) => {
    setFileList(fileList.filter((_, index) => index !== fileIndex));
  }

  return (
    <Row className={'w-full grid grid-cols-1 gap-y-4'}>
      {fileList.map((file, index) =>
        <Col key={file.name} className={'flex gap-x-3'}>
          <InputComponent value={file.name}
                          title={file.name}
                          disabled={true}
                          readOnly={true}
                          className={'bg-white text-grey-dkt-600'} />
          <Tooltip title={'Quitar archivo'}>
            <DeleteOutlined className={'text-lg m-auto cursor-pointer hover:text-red-dkt-400'}
                            onClick={() => handleRemoveFile(index)} />
          </Tooltip>
        </Col>
      )}

      <Col className={'text-center w-full mt-5'}>
        <Upload showUploadList={false}
                multiple={false}
                accept={acceptedFormats}
                beforeUpload={(file) => beforeUpload(file, 2)}
                customRequest={dummyRequest}
        >
            <ButtonComponent text={'Agregar archivo'}
                             icon={<FileAddOutlined />}
                             className={'h-11 bg-white border border-grey-dkt-300 leading-4 text-grey-dkt-700 ' +
                               'hover:bg-grey-dkt-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dkt-400'} />
        </Upload>
      </Col>
    </Row>
  );
}

export default Files;