// Store
import {setLoading} from './generalActions';
import {AppDispatch} from '../../store';
import plannerServices from '../../../api/plannerServices';

// Types
import {plannerTypes} from '../types';
import {DuplicateEventType, PlannerType} from "../../../types/plannerTypes";

// Components and Subcomponents
import {ERROR_MODAL, SUCCESS_MODAL} from '../../../components/subComponents/responseModals';


export const getParams = (month: number, year: number) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  plannerServices.getParams()
    .then((response) => {
      dispatch({
        type: plannerTypes.GET_PLANNER_PARAMS,
        params: response.data
      });
      dispatch(getAllByMonth(month, year));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const getAllByMonth = (month: number, year: number) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  plannerServices.getAllByMonth(month, year)
    .then((response) => {
      dispatch({
        type: plannerTypes.GET_ALL_PLANNER,
        planner: response.data
      });
      dispatch(setLoading(false));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const create = (data: PlannerType, month: number, year: number) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  plannerServices.create(data)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAllByMonth(month, year));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const duplicateEvents = (data: DuplicateEventType, month: number, year: number) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  plannerServices.duplicateEvents(data)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAllByMonth(month, year));
    })
    .catch(err => {
      ERROR_MODAL(err.data.message);
      dispatch(setLoading(false));
    })
}
export const update = (data: PlannerType, month: number, year: number) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  plannerServices.update(data)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAllByMonth(month, year));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
export const remove = (plannerId: string, month: number, year: number) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  plannerServices.remove(plannerId)
    .then((response) => {
      SUCCESS_MODAL(response.data);
      dispatch(getAllByMonth(month, year));
    })
    .catch(err => {
      ERROR_MODAL(err.data.error);
      dispatch(setLoading(false));
    })
}
