// Libraries
import React from 'react';
import {Descriptions} from 'antd';

// Utils
import {useSelector} from "react-redux";

// Store
import * as transportSelectors from "../../../store/redux/selectors/transportSelectors";


const CarrierInformation = () => {
  const { carriers, carTypes } = useSelector(transportSelectors.transportParamsSelector);
  const detail = useSelector(transportSelectors.transportDetailSelector);

  return (
    <Descriptions bordered
                  column={2}
                  size={'small'}>
      <Descriptions.Item label={"Nombre"} span={2}>
        {carriers.find((carrier) => carrier.id === detail.carrierId)?.value}
      </Descriptions.Item>
      <Descriptions.Item label={"Placa"}>{detail.carrierResponse?.carPlate}</Descriptions.Item>
      <Descriptions.Item label={"Tipo de vehículo"}>
        {carTypes.find((car) => car.id === detail.carrierResponse?.vehicleType)?.value}
      </Descriptions.Item>
      <Descriptions.Item label={"Conductor"}>{detail.carrierResponse?.driverName}</Descriptions.Item>
      <Descriptions.Item label={"Identificación del conductor"}>{detail.carrierResponse?.driverIdentification}</Descriptions.Item>
      <Descriptions.Item label={"Teléfono del conductor"}>{detail.carrierResponse?.driverPhone}</Descriptions.Item>
      <Descriptions.Item label={"ARL del conductor"}>{detail.carrierResponse?.driverArl}</Descriptions.Item>
    </Descriptions>
  );
}

export default CarrierInformation;
